import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import CardImg from "./utils/CardImg";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import { TailContent, TailTitle, TailWrapper } from "./utils/styles";

const HeadlightRegeneration = () => {
  const imageData = useStaticQuery(graphql`
    query {
      regenRefl: file(relativePath: { eq: "offer/polerowanie_lamp.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const {
    servicePrices: { headlightRegeneration: servicePrice },
  } = usePricingCardsContext();

  return (
    <TailWrapper id="polerowanieLamp">
      <TailTitle>Regeneracja Reflektorów + Zabezpieczenie</TailTitle>
      <CardImg
        fluid={imageData.regenRefl.childImageSharp.fluid}
        alt="Regeneracja Reflektorów"
      />
      <TailContent>
        <ListItem>
          polerowanie specjalnie dobranymi <span>pastami polerskimi</span>{" "}
          różnej gradacji
        </ListItem>
        <ListItem>aplikacja twardego wosku</ListItem>
      </TailContent>
      <PriceButton from price={servicePrice} />
    </TailWrapper>
  );
};

export default HeadlightRegeneration;
