import React, { createContext, useState } from "react";

export const SliderContext = createContext();

const SliderContextProvider = ({ children }) => {
  const [index, setIndex] = useState(0);
  const value = {
    index,
    setIndex,
  };
  return (
    <SliderContext.Provider value={value}>{children}</SliderContext.Provider>
  );
};
export default SliderContextProvider;
