import React from "react";
import styled from "styled-components";

const StyledDot = styled.div`
  width: 14px;
  height: 14px;
  border: 2px solid ${({ theme }) => theme.colors.whitest};
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => isActive && theme.colors.white};
  transition: background-color 0.4s ease;
  &:hover {
    cursor: pointer;
  }
`;

const Dot = ({ isActive, onClick }) => {
  return <StyledDot onClick={onClick} isActive={isActive} />;
};

export default Dot;
