import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.7rem;
  height: auto;
  padding: 15px 20px 0 20px;
  transform: rotate(45deg);
  animation: rotateIn 300ms ease-out forwards;

  @keyframes rotateIn {
    50% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    display: none;
  }
`;

const MainMenuIcon = ({ menuShown, toggleMenu }) => {
  return (
    <Wrapper onClick={toggleMenu}>
      {menuShown ? <FaTimes /> : <FaBars />}
    </Wrapper>
  );
};

export default MainMenuIcon;
