import React, { createContext, useContext, useState } from "react";

const carType = {
  medium: "medium",
  large: "large",
};
const servicePrices = {
  outside: {
    [carType.medium]: 75,
    [carType.large]: 85,
  },
  inside: {
    [carType.medium]: 70,
    [carType.large]: 80,
  },
  premium: {
    [carType.medium]: 150,
    [carType.large]: 160,
  },
  upholsteryCleaning: 180,
  premiumInside: 300,
  washingAndWaxing: {
    threeMonths: 150,
    sixMonths: 200,
  },
  headlightRegeneration: 180,
};

const PricingCardsContext = createContext();

const PricingCardsContextProvider = ({ children }) => {
  const [selectedCarType, setSelectedCarType] = useState(carType.medium);

  const setSmall = () => setSelectedCarType(carType.small);
  const setMedium = () => setSelectedCarType(carType.medium);
  const setLarge = () => setSelectedCarType(carType.large);
  const value = {
    selectedCarType,
    setSmall,
    setMedium,
    setLarge,
    carType,
    servicePrices,
  };
  return (
    <PricingCardsContext.Provider value={value}>
      {children}
    </PricingCardsContext.Provider>
  );
};
export default PricingCardsContextProvider;

export const usePricingCardsContext = () => useContext(PricingCardsContext);
