import React from "react";
import { StyledListItem, CheckMark, ListText } from "./styles";

const ListItem = ({ children }) => {
  return (
    <StyledListItem>
      <CheckMark />
      <ListText>{children}</ListText>
    </StyledListItem>
  );
};

export default ListItem;
