import React from "react";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import CarTypeSelection from "./utils/CarTypeSelection";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import { TailContent, TailTitle, TailWrapper } from "./utils/styles";
const Outside = () => {
  const {
    selectedCarType,
    servicePrices: { outside: servicePrice },
  } = usePricingCardsContext();

  return (
    <TailWrapper id="mycieZewnetrzne">
      <TailTitle>Mycie Zewnętrzne</TailTitle>
      <CarTypeSelection />
      <TailContent>
        <ListItem>szyby</ListItem>
        <ListItem>karoseria</ListItem>
        <ListItem>progi</ListItem>
        <ListItem>felgi</ListItem>
        <ListItem>osuszanie</ListItem>
        <ListItem>
          <span>impregnacja opon</span>
        </ListItem>
        <ListItem>
          <span>Quick Detailer</span>
        </ListItem>
      </TailContent>
      <PriceButton price={servicePrice[selectedCarType]} />
    </TailWrapper>
  );
};

export default Outside;
