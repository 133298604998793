import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(
    rgba(52, 73, 94, 0.7) 30%,
    rgba(52, 73, 94, 0.01)
  );
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 3rem;
  margin-bottom: 5px;
`;

const About = styled.div`
  margin-top: 15px;
  width: 95%;
  max-width: 768px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 25px;
`;

const Map = styled.a`
  width: 90%;
  height: 100vh;
  max-width: 1280px;
  max-height: 480px;
  border-radius: 10px;
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    max-height: 700px;
  }
`;

const MobileWashing = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      map: file(relativePath: { eq: "mapa.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper id={id}>
      <Title>Mobilne Mycie Samochodu</Title>
      <About>
        GarCar Detailing-Mobilna myjnia parowa to prężnie rozwijająca się firma
        zajmująca się kosmetyką samochodową. Świadczymy usługi na terenie
        Gliwic-Pyskowic i okolic. Dzieki ekspertyzie środowiskowej którą posiada
        nasz sprzęt, możemy umyć samochód parą w niemal każdym miejscu.
      </About>
      <Map href="https://g.page/myjniagarcar?gm" target="_blank">
        <Img
          style={{ width: "100%", height: "100%" }}
          fluid={data.map.childImageSharp.fluid}
          alt="podstawowy obsługiwany obszar"
        ></Img>
      </Map>
      {/* <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163025.1191884029!2d18.506326739568756!3d50.3242606523281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471d7bce0f758417%3A0xcd6af034bd89822!2sGarCar-Mobilna%20myjnia%20parowa!5e0!3m2!1spl!2spl!4v1570710695343!5m2!1spl!2spl&zoom=13"></Map> */}
    </Wrapper>
  );
};

export default MobileWashing;
