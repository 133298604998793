import React from "react";
import { FaCar, FaTruckPickup } from "react-icons/fa";
import { usePricingCardsContext } from "../../../../contexts/PricingCardsContext";
import { CarIconText, CarSelectSection, CarTypeIcon } from "./styles";

const CarTypeSelection = () => {
  const {
    selectedCarType,
    setMedium,
    setLarge,
    carType,
  } = usePricingCardsContext();
  return (
    <CarSelectSection>
      <CarTypeIcon
        active={selectedCarType === carType.medium}
        onClick={setMedium}
      >
        <FaCar />
        <CarIconText>osobowe</CarIconText>
      </CarTypeIcon>
      <CarTypeIcon
        active={selectedCarType === carType.large}
        onClick={setLarge}
      >
        <FaTruckPickup />
        <CarIconText>
          <div>terenowe</div>
          <span>SUV/VAN</span>
        </CarIconText>
      </CarTypeIcon>
    </CarSelectSection>
  );
};

export default CarTypeSelection;
