import { keyframes } from "styled-components";

export const slideFromTop = keyframes`
    0% {
      transform: translateY(-100%) scale(1, 0);
      opacity: 0;
    }
    100% {
      transform-origin: top;
      transform: translateY(0) scale(1, 1);
      opacity: 1;
    }
    `;

export const pulseHighlight = keyframes`
    0% {
      transform: scale(1) translateX(-10px) rotate(5deg);
    }
    50%{
      transform: scale(1.2) translateX(-10px) rotate(5deg);
    }
    100% {
      transform: scale(1) translateX(-10px) rotate(5deg);
    `;
