export default {
  colors: {
    backgroundColor: "#34495e",
    dark: "#222831",
    lightDark: "#393e46",
    blue: "#00adb5",
    white: "#eeeeee",
    whitest: "#fff",
    yellow: "#f1c40f",
  },
  MQ: {
    mobile: "1024px",
  },
};
