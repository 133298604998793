import React, { useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import MenuItem from "./MenuItem";
import MainMenuIcon from "./MainMenuIcon";
import { headerHeight } from "../../utils/constants";

const StyledHeader = styled.header`
  z-index: 666;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  height: ${headerHeight}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark};
  transform: translateY(${({ headerShown }) => (headerShown ? 0 : "-150%")});
  transition: transform 0.8s ease-out, background-color 0.5s ease;
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    background-color: ${({ theme, semiTransparent }) =>
      semiTransparent ? theme.colors.dark + "aa" : theme.colors.dark};
    transform: none;
  }
`;

const Navigation = styled.nav`
  position: absolute;
  transform: ${({ menuShown }) => (menuShown ? "scale(1,1)" : "scale(1,0)")};
  transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  transform-origin: top;
  top: 70px;
  right: 0;
  width: 100%;
  opacity: ${({ menuShown }) => (menuShown ? 1 : 0)};

  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    position: static;
    width: auto;
    transform: scale(1);
    opacity: 1;
    display: flex;
  }
`;

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
`;

const Header = () => {
  const [menuShown, setMenuShown] = useState(false);
  const toggleMenu = useCallback(() => setMenuShown(prev => !prev), []);
  const hideMenu = useCallback(() => setMenuShown(false), []);
  const [semiTransparentHeader, setSemiTransparentHeader] = useState(false);
  const lastScrollUpPosition = useRef(0);
  const lastScrollPosition = useRef(0);
  const [headerShown, setHeaderShown] = useState(true);

  const handleScroll = () => {
    //transparent header (for desktop)
    if (window.scrollY > headerHeight) setSemiTransparentHeader(true);
    else if (window.scrollY < headerHeight) setSemiTransparentHeader(false);

    //hiding header (for mobiles)
    if (window.scrollY < lastScrollPosition.current) {
      setHeaderShown(true);
      lastScrollUpPosition.current = window.scrollY;
    } else if (window.scrollY - lastScrollUpPosition.current > headerHeight) {
      setHeaderShown(false);
      setMenuShown(false);
    }
    lastScrollPosition.current = window.scrollY;
  };

  useEffect(() => {
    lastScrollUpPosition.current = window.scrollY;
    lastScrollPosition.current = window.scrollY;
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <StyledHeader
      semiTransparent={semiTransparentHeader}
      headerShown={headerShown}
    >
      <Logo menuShown={menuShown} />
      <MainMenuIcon toggleMenu={toggleMenu} menuShown={menuShown} />
      <Navigation menuShown={menuShown}>
        <MenuList onClick={hideMenu}>
          <MenuItem to={"#oferta"} index={0}>
            Oferta
          </MenuItem>
          <MenuItem to={"#cennik"} index={1}>
            Cennik
          </MenuItem>
          <MenuItem to={"#kontakt"} index={2}>
            Kontakt
          </MenuItem>
          {/* <MenuItem to={"#formularzKontaktowy"} index={3} topRightText="-10%">
            Umów się online
          </MenuItem> */}
        </MenuList>
      </Navigation>
    </StyledHeader>
  );
};

export default Header;
