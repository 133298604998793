import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  z-index: 9999;
  color: ${({ theme }) => theme.colors.whitest};
  background-color: ${({ theme }) => theme.colors.dark + "ee"};
  @keyframes hide {
    100% {
      transform: translateY(150%);
    }
  }
  animation: hide 2s ease 15s forwards;
`;
const CookieLink = styled.a.attrs({
  href: "https://wszystkoociasteczkach.pl/",
  rel: "noopener noreferrer nofollow",
  target: "_blank",
})`
  color: inherit;
`;
const CloseButton = styled(FaTimes)`
  position: absolute;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.yellow};
  left: 15px;
  top: 0;
  bottom: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

const CookieReminder = ({ onClose }) => {
  return (
    <Wrapper>
      Strona wykorzystuje pliki&nbsp;
      <CookieLink>Cookies</CookieLink>
      <CloseButton onClick={onClose} />
    </Wrapper>
  );
};

export default CookieReminder;
