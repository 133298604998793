import React from "react";
import styled from "styled-components";

const Container = styled.footer`
  /* background-color: ${({ theme }) => theme.colors.backgroundColor}; */
  background-color: ${({ theme }) => theme.colors.dark};
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 0.8rem;
  & p {
    color: inherit;
    margin: 5px;
    a {
      color: white;
    }
  }
  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    font-size: 1rem;
  }
`;

const Footer = () => {
  return (
    <Container>
      <p>&copy;GarCar Detailing - Mobilna myjnia parowa</p>
    </Container>
  );
};

export default Footer;
