import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";

const Wrapper = styled.div`
  width: 90%;
  border-radius: 5px;
  max-height: 200px;
  overflow: hidden;
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
  backface-visibility: hidden;
  @keyframes initialAnimation {
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  animation: ${({ animate }) => animate && "initialAnimation 2s ease-in-out"};
  &:hover {
    transform: scale(1.1);
  }
`;

const CardImg = ({ fluid, alt }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  return (
    <Wrapper ref={ref}>
      <StyledImg fluid={fluid} alt={alt} animate={inView} />
    </Wrapper>
  );
};

export default CardImg;
