import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled, { css } from "styled-components";
import { slideFromTop } from "./animations";

const StyledLogo = styled(Img)`
  width: 150px;
  height: auto;
  transform: ${({ menuShown }) =>
    menuShown ? "translateY(0)" : "translateY(10px)"};
  transition: transform 400ms linear;
  margin-top: 10px;
  z-index: 1;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    width: 185px;
    opacity: 0;
    ${css`
      animation: ${slideFromTop} 1s ease 1s forwards;
    `};
    margin-top: 20px;
  }
`;

const Logo = ({ menuShown }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <a href={"#oferta"}>
      <StyledLogo
        fluid={data.logo.childImageSharp.fluid}
        menuShown={menuShown}
        alt="GarCar Logo"
        loading="eager"
      />
    </a>
  );
};

export default Logo;
