import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import {
  CarSelectSection as DurabilitySection,
  TailContent,
  TailTitle,
  TailWrapper,
} from "./utils/styles";

const SubTitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
`;
const DurabilityBox = styled.div.attrs({ role: "button" })`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 15px 10px;
  border-radius: 10px;
  border: ${({ theme, active }) =>
    active
      ? `3px solid ${theme.colors.blue}`
      : `1px solid ${theme.colors.white + "66"}`};
  transform: scale(${({ active }) => (active ? 1.2 : 1)});
  transition: transform 0.5s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;
const durabilities = {
  threeMonths: "3mies",
  sixMonths: "6mies",
};

const WashingAndWaxing = () => {
  const {
    servicePrices: { washingAndWaxing: servicePrice },
  } = usePricingCardsContext();

  const [selectedDurability, setSelectedDurability] = useState(
    durabilities.threeMonths
  );

  useEffect(() => {
    if (selectedDurability === durabilities.threeMonths) {
      setPrice(servicePrice.threeMonths);
    } else {
      setPrice(servicePrice.sixMonths);
    }
    // eslint-disable-next-line
  }, [selectedDurability]);
  const [price, setPrice] = useState(0);

  const selectThreeMonths = () =>
    setSelectedDurability(durabilities.threeMonths);
  const selectSixMonths = () => setSelectedDurability(durabilities.sixMonths);
  return (
    <TailWrapper id="zabezpieczenieLakieru">
      <TailTitle>Mycie + Zabezpieczenie twardym woskiem</TailTitle>
      <SubTitle>Trwałość:</SubTitle>
      <DurabilitySection>
        <DurabilityBox
          onClick={selectThreeMonths}
          active={selectedDurability === durabilities.threeMonths}
        >
          3 miesiące
        </DurabilityBox>
        <DurabilityBox
          onClick={selectSixMonths}
          active={selectedDurability === durabilities.sixMonths}
        >
          6 miesięcy
        </DurabilityBox>
      </DurabilitySection>
      <TailContent>
        <ListItem>Mycie parą: karoserii, szyb, felg, progów</ListItem>
        <ListItem>Nabłyszczenie opon</ListItem>
        <ListItem>Odtłuszczenie lakieru</ListItem>
        <ListItem>
          Aplikacja <span>twardego</span> wosku na karoserię
        </ListItem>
      </TailContent>
      <PriceButton from price={price} />
    </TailWrapper>
  );
};

export default WashingAndWaxing;
