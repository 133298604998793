import { createGlobalStyle } from "styled-components";
import { headerHeight } from "./constants";

const GlobalStyles = createGlobalStyle`
html{
  scroll-behavior: smooth;
  font-family: "Quicksand";
}
body,html{
  margin: 0;
  padding: 0;
  @media (min-width:${({ theme }) => theme.MQ.mobile}){
    scroll-padding-top: ${headerHeight - 1}px;
  }
  scroll-padding-top: 1px;
}
body{
  width:100%;
}
*,*::before,*::after{
  box-sizing: border-box;
}
.anticon-spin{
  @keyframes spin {
    from{
      transform:rotate(0);
    }
    to{
      transform:rotate(360deg);
    }
  }
  animation: spin 2s linear infinite;
}
`;

export default GlobalStyles;
