import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.7s ease;
  z-index: ${({ active }) => (active ? 1 : 0)};
`;
const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &::before,
  &::after {
    filter: brightness(75%);
  }
`;
const Text = styled.h1`
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 3rem;
  max-width: 768px;
  font-weight: bold;
  line-height: 3.2rem;
  padding: 0.5rem;
  margin: 0;
  transform: ${({ active }) =>
    active ? "translateY(0)" : "translateY(-50px)"};
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: transform 0.5s ease, opacity 0.5s ease;
`;
const Button = styled.button`
  width: 250px;
  height: 100px;
  margin: 0 auto;
  border-radius: 7px;
  border: none;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.whitest};
  font-size: 1.7rem;
  transform: ${({ active }) => (active ? "translateY(0)" : "translateY(50px)")};
  transition: transform 0.5s ease;

  &:hover {
    cursor: pointer;
  }
`;
const Tail = React.memo(
  ({ text, bgSrc, active, toId, buttonText = "Sprawdź cenę" }) => {
    return (
      <StyledWrapper active={active}>
        <StyledBackground fluid={bgSrc} alt={text}>
          <Text active={active}>{text}</Text>
          <a href={`#${toId}`}>
            <Button active={active}>{buttonText}</Button>
          </a>
        </StyledBackground>
      </StyledWrapper>
    );
  }
);

export default Tail;
