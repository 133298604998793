import React, { createContext, useContext, useEffect, useRef } from "react";
import Hammer from "react-hammerjs";
import styled from "styled-components";
import { SliderContext } from "../../contexts/SliderContext";
import Dot from "./Dot";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
const DotsWrapper = styled.div`
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 200px;
  z-index: 10;
`;

export const CarouselContext = createContext();

const Slider = ({ children, autoplay }) => {
  const { setIndex, index: currentSliderIndex } = useContext(SliderContext);

  const intervalRef = useRef(null);

  const handleSwipe = swipe => {
    if (swipe.direction === 2) slideRight();
    else if (swipe.direction === 4) slideLeft();
  };

  const slideRight = () => {
    if (autoplay) resetInterval();
    setIndex(curr => (curr < children.length - 1 ? curr + 1 : 0));
  };

  const slideLeft = () => {
    if (autoplay) resetInterval();
    setIndex(curr => (curr > 0 ? curr - 1 : children.length - 1));
  };

  const resetInterval = () => {
    stopInterval();
    intervalRef.current = setInterval(slideRight, 5000);
  };

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (autoplay) {
      resetInterval();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Hammer onSwipe={handleSwipe}>
      <StyledWrapper>
        {children}
        <DotsWrapper>
          {new Array(children.length).fill(null).map((_, i) => (
            <Dot
              key={i}
              isActive={currentSliderIndex === i}
              onClick={() => {
                setIndex(i);
                resetInterval();
              }}
            />
          ))}
        </DotsWrapper>
      </StyledWrapper>
    </Hammer>
  );
};
export default Slider;
