import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Contact from "../components/Contact/Contact";
import CookieReminder from "../components/CookieReminder";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import MessengerBubble from "../components/MessengerBubble";
import MobileWashing from "../components/MobileWashing/MobileWashing";
import Offer from "../components/Offer/Offer";
import PriceList from "../components/PriceList/PriceList";
import SEO from "../components/Seo";
import SliderContextProvider from "../contexts/SliderContext";
import vapor_bg from "../images/vapor_bg.png";
import "../utils/fonts/fonts.css";
import GlobalStyles from "../utils/globalStyles";
import theme from "../utils/theme";

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background: url(${vapor_bg}) center center;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

const IndexPage = () => {
  const [cookieReminderShown, setCookieReminderShown] = useState(true);
  const [showMessengerBubble, setShowMessengerBubble] = useState(true);

  useEffect(() => {
    try {
      const parsedValue = JSON.parse(
        window.localStorage.getItem("cookieReminderShown")
      );
      setCookieReminderShown(parsedValue ? true : false);
    } catch (err) {
      setCookieReminderShown(false);
    }
  }, []);

  const handleCloseReminder = () => {
    setCookieReminderShown(true);
    window.localStorage.setItem("cookieReminderShown", "true");
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <PageWrapper>
          <SEO title="GarCar Detailing-Mobilna myjnia parowa" />
          <GlobalStyles />
          <Header />
          <SliderContextProvider>
            <Offer id="oferta" />
          </SliderContextProvider>
          <MobileWashing id="mobilneMycie" />
          <PriceList id="cennik" />
          <Contact
            id="kontakt"
            setShowMessengerBubble={setShowMessengerBubble}
          />
          <Footer />
          {showMessengerBubble && (
            <MessengerBubble href="https://m.me/garcarmyjnia" />
          )}
          {!cookieReminderShown && (
            <CookieReminder onClose={handleCloseReminder} />
          )}
        </PageWrapper>
      </>
    </ThemeProvider>
  );
};

export default IndexPage;
