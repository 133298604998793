import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import CardImg from "./utils/CardImg";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import { TailContent, TailTitle, TailWrapper } from "./utils/styles";

const UpholsteryCleaning = () => {
  const imageData = useStaticQuery(graphql`
    query {
      UpCleaning: file(relativePath: { eq: "offer/pranie_tapicerki.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const {
    servicePrices: { upholsteryCleaning: servicePrice },
  } = usePricingCardsContext();

  return (
    <TailWrapper id="pranieTapicerki">
      <TailTitle>Pranie Tapicerki</TailTitle>
      <CardImg
        fluid={imageData.UpCleaning.childImageSharp.fluid}
        alt="Pranie Tapicerki"
      />
      <TailContent>
        <ListItem>odkurzanie samochodu</ListItem>
        <ListItem>
          <span>pranie ekstrakcyjne</span>
        </ListItem>
        <ListItem>usuwanie plam i zapachów</ListItem>
      </TailContent>
      <PriceButton from price={servicePrice} />
    </TailWrapper>
  );
};

export default UpholsteryCleaning;
