import React from "react";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import CarTypeSelection from "./utils/CarTypeSelection";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import {
  PopularLabel,
  TailContent,
  TailTitle,
  TailWrapper,
} from "./utils/styles";

const Premium = () => {
  const {
    selectedCarType,
    servicePrices: { premium: servicePrice },
  } = usePricingCardsContext();

  return (
    <TailWrapper>
      <PopularLabel>Popularne</PopularLabel>
      <TailTitle>Mycie Premium</TailTitle>
      <CarTypeSelection />
      <TailContent>
        <ListItem>zawartość pakietu zewnętrznego</ListItem>
        <ListItem>zawartość pakietu wewnętrznego</ListItem>
        <ListItem>amplifikacja spektrum zapachowego środka pojazdu</ListItem>
      </TailContent>
      <PriceButton price={servicePrice[selectedCarType]} />
    </TailWrapper>
  );
};

export default Premium;
