import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";
import { SliderContext } from "../../contexts/SliderContext";
import Slider from "../Slider/Slider";
import Tail from "./Tail";

const StyledWrapper = styled.section`
  height: 95vh;
  width: 100%;
`;

const Offer = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      MM: file(relativePath: { eq: "offer/mobilne_mycie.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MZ: file(relativePath: { eq: "offer/mycie_zewnetrzne.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MW: file(relativePath: { eq: "offer/mycie_wewnetrzne.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PL: file(relativePath: { eq: "offer/polerowanie_lamp.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ZL: file(relativePath: { eq: "offer/zabezpieczenie_lakieru.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PT: file(relativePath: { eq: "offer/pranie_tapicerki.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { index } = useContext(SliderContext);
  const tailsData = [
    {
      id: 0,
      bgSrc: data.MM.childImageSharp.fluid,
      text: "Mobilne mycie samochodu",
      buttonText: "Czytaj więcej",
      toId: "mobilneMycie",
    },
    {
      id: 1,
      bgSrc: data.MZ.childImageSharp.fluid,
      text: "Mycie zewnętrzne",
      toId: "mycieZewnetrzne",
    },
    {
      id: 2,
      bgSrc: data.MW.childImageSharp.fluid,
      text: "Mycie wewnętrzne",
      toId: "mycieWewnetrzne",
    },
    {
      id: 3,
      bgSrc: data.PL.childImageSharp.fluid,
      text: "Polerowanie lamp",
      toId: "polerowanieLamp",
    },
    {
      id: 4,
      bgSrc: data.ZL.childImageSharp.fluid,
      text: "Zabezpieczenie lakieru",
      toId: "zabezpieczenieLakieru",
    },
    {
      id: 5,
      bgSrc: data.PT.childImageSharp.fluid,
      text: "Pranie tapicerki",
      toId: "pranieTapicerki",
    },
  ];
  return (
    <StyledWrapper id={id}>
      <Slider autoplay={true}>
        {tailsData.map(({ id, buttonText, bgSrc, text, toId }) => (
          <Tail
            key={id}
            active={index === id}
            bgSrc={bgSrc}
            buttonText={buttonText}
            text={text}
            toId={toId}
          />
        ))}
      </Slider>
    </StyledWrapper>
  );
};

export default Offer;
