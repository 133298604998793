import React from "react";
import styled, { css } from "styled-components";
import { pulseHighlight, slideFromTop } from "./animations";

const StyledMenuItem = styled.li`
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.whitest};
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightDark};

  ${({ topRightText }) => css`
      && a::after {
        content: "${topRightText}";
        position: absolute;
        top: 5px;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.yellow};
        animation: ${({ delay }) =>
          css`1s ${pulseHighlight} ${delay * 0.5 + 2}s ease-in forwards`};
        transform: translateX(-10px) rotate(5deg);
      }
    `}

  a {
    color: inherit;
    text-decoration: none;
    display: block;
    position: relative;
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.MQ.mobile}) {
    width: auto;
    border-bottom: none;
    background-color: transparent;
    margin: 15px;
    opacity: 0;
    animation: ${({ delay }) =>
      css`1s ${slideFromTop} ${delay * 0.5 + 1.5}s ease forwards`};

    &::after {
      content: "";
      width: 100%;
      display: block;
      height: 3px;
      background-color: ${({ theme }) => theme.colors.whitest};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s ease;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
`;

const MenuItem = ({ children, to, index, topRightText }) => {
  return (
    <StyledMenuItem delay={index} topRightText={topRightText}>
      <a href={to}>{children}</a>
    </StyledMenuItem>
  );
};

export default MenuItem;
