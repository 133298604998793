import React from "react";
import { usePricingCardsContext } from "../../../contexts/PricingCardsContext";
import CarTypeSelection from "./utils/CarTypeSelection";
import ListItem from "./utils/CheckListItem";
import PriceButton from "./utils/PriceButton";
import { TailContent, TailTitle, TailWrapper } from "./utils/styles";

const Inside = () => {
  const {
    selectedCarType,
    servicePrices: { inside: servicePrice },
  } = usePricingCardsContext();

  return (
    <TailWrapper id="mycieWewnetrzne">
      <TailTitle>Mycie Wewnętrzne</TailTitle>
      <CarTypeSelection />
      <TailContent>
        <ListItem>szyby</ListItem>
        <ListItem>odkurzanie wnętrza</ListItem>
        <ListItem>odkurzanie bagażnika</ListItem>
        <ListItem>kokpit</ListItem>
        <ListItem>pozostałe plastiki</ListItem>
      </TailContent>
      <PriceButton price={servicePrice[selectedCarType]} />
    </TailWrapper>
  );
};

export default Inside;
